enum UserPermission {
  // Actions.
  CancelPayment = 'cancelPayment',
  CapturePayment = 'capturePayment',
  CreateParcel = 'createParcel',
  CreateRefund = 'createRefund',
  ExportBarcode = 'exportBarcode',
  GetPaymentUrl = 'getPaymentUrl',
  UpdateProductStock = 'updateProductStock',

  // Actions (data export).
  AdminExportAmbassadors = 'admin.exportAmbassadors',
  AdminExportBrands = 'admin.exportBrands',
  AdminExportOrders = 'admin.exportOrders',
  AdminExportOrdersStatistics = 'admin.exportOrdersStatistics',
  AdminExportPaidOrdersAndUsersStatistics = 'admin.exportPaidOrdersAndUsersStatistics',
  AdminExportProducts = 'admin.exportProducts',
  AdminExportPromoCodes = 'admin.exportPromoCodes',
  AdminExportSalesStatistics = 'admin.exportSalesStatistics',
  AdminExportSuppliers = 'admin.exportSuppliers',
  AdminExportUsers = 'admin.exportUsers',
  ClientExportRecommendationSupplements = 'client.exportRecommendationSupplements',
  SupplierExportProducts = 'supplier.exportProducts',
  SupplierExportSalesStatistics = 'supplier.exportSalesStatistics',

  // Ambassadors.
  CreateAmbassador = 'createAmbassador',
  GetAmbassador = 'getAmbassador',
  GetAmbassadors = 'getAmbassadors',
  UpdateAmbassador = 'updateAmbassador',

  // Articles.
  CreateArticle = 'createArticle',
  GetArticleAsAdmin = 'getArticleAsAdmin',
  GetArticlesAsAdmin = 'getArticlesAsAdmin',
  UpdateArticle = 'updateArticle',
  DeleteArticle = 'deleteArticle',

  // Brands.
  AdminCreateBrand = 'admin.createBrand',
  SupplierCreateBrand = 'supplier.createBrand',
  AdminGetBrands = 'admin.getBrands',
  SupplierGetBrands = 'supplier.getBrands',
  AdminUpdateBrand = 'admin.updateBrand',
  AdminDeleteBrand = 'admin.deleteBrand',

  // Categories.
  CreateCategory = 'createCategory',
  GetCategoryAsAdmin = 'getCategoryAsAdmin',
  GetCategoriesAsAdmin = 'getCategoriesAsAdmin',
  UpdateCategory = 'updateCategory',
  DeleteCategory = 'deleteCategory',

  // Certificates.
  CreateCertificate = 'createCertificate',
  GetCertificate = 'getCertificate',
  GetCertificates = 'getCertificates',
  UpdateCertificate = 'updateCertificate',
  DeleteCertificate = 'deleteCertificate',

  // Collections.
  CreateCollection = 'createCollection',
  GetCollectionAsAdmin = 'getCollectionAsAdmin',
  GetCollectionsAsAdmin = 'getCollectionsAsAdmin',
  UpdateCollection = 'updateCollection',
  DeleteCollection = 'deleteCollection',

  // Consultations
  AdminGetConsultations = 'admin.getConsultations',
  SpecialistGetConsultations = 'specialist.getConsultations',

  // Messages.
  GetMessage = 'getMessage',
  GetMessages = 'getMessages',
  UpdateMessage = 'updateMessage',
  DeleteMessage = 'deleteMessage',

  // Orders.
  CreateBloggerOrder = 'createBloggerOrder',
  CreateRepeatOrder = 'createRepeatOrder',
  GetOrder = 'getOrder',
  GetOrders = 'getOrders',
  UpdateOrder = 'updateOrder',
  DeleteOrder = 'deleteOrder',

  // Product changes.
  AdminApproveProductChanges = 'admin.approveProductChanges',
  AdminCancelProductChanges  = 'admin.cancelProductChanges',
  AdminGetProductChanges = 'admin.getProductChanges',

  // Products.
  AdminCreateProduct = 'admin.createProduct',
  AdminGetProduct = 'admin.getProduct',
  AdminGetProducts = 'admin.getProducts',
  AdminUpdateProduct = 'admin.updateProduct',
  AdminDeleteProduct = 'admin.deleteProduct',
  SupplierCreateProduct = 'supplier.createProduct',
  SupplierGetProduct = 'supplier.getProduct',
  SupplierGetProducts = 'supplier.getProducts',
  SupplierUpdateProduct = 'supplier.updateProduct',

  // Promo code applications.
  GetPromoCodeApplication = 'getPromoCodeApplication',
  GetPromoCodeApplications = 'getPromoCodeApplications',
  UpdatePromoCodeApplication = 'updatePromoCodeApplication',

  // Promo codes.
  CreatePromoCode = 'createPromoCode',
  GetPromoCode = 'getPromoCodeAsAdmin',
  GetPromoCodes = 'getPromoCodes',
  UpdatePromoCode = 'updatePromoCode',
  DeletePromoCode = 'deletePromoCode',

  // Quality certificates.
  CreateQualityCertificate = 'createQualityCertificate',
  CreateQualityCertificateAsAdmin = 'createQualityCertificateAsAdmin',
  GetQualityCertificate = 'getQualityCertificate',
  GetQualityCertificates = 'getQualityCertificates',
  UpdateQualityCertificate = 'updateQualityCertificate',
  UpdateQualityCertificateAsAdmin = 'updateQualityCertificateAsAdmin',

  // Reviews.
  GetReviewAsAdmin = 'getReviewAsAdmin',
  GetReviewsAsAdmin = 'getReviewsAsAdmin',
  UpdateReviewAsAdmin = 'updateReviewAsAdmin',
  DeleteReviewAsAdmin = 'deleteReviewAsAdmin',
  SupplierAnswerReview = 'supplier.answerReview',

  // Scripts.
  UpdateProductPositions = 'updateProductPositions',
  UpdateThisWeekDiscounts = 'updateThisWeekDiscounts',

  // Special offers.
  CreateSpecialOffer = 'createSpecialOffer',
  GetSpecialOfferAsAdmin = 'getSpecialOfferAsAdmin',
  GetSpecialOffersAsAdmin = 'getSpecialOffersAsAdmin',
  UpdateSpecialOffer = 'updateSpecialOffer',
  DeleteSpecialOffer = 'deleteSpecialOffer',

  // Specialist applications.
  AdminUpdateSpecialistApplication = 'admin.updateSpecialistApplication',

  // Specialists.
  AdminGetSpecialists = 'admin.getSpecialists',
  CreateSpecialist = 'createSpecialist',
  UpdateSpecialist = 'updateSpecialist',
  DeleteSpecialist = 'deleteSpecialist',

  // Supplier applications.
  AdminGetSupplierApplication = 'admin.getSupplierApplication',
  AdminGetSupplierApplications = 'admin.getSupplierApplications',
  AdminUpdateSupplierApplication = 'admin.updateSupplierApplication',

  // Supplier FAQs.
  AdminGetSupplierFaq = 'admin.getSupplierFaq',
  AdminGetSupplierFaqs = 'admin.getSupplierFaqs',
  AdminCreateSupplierFaq = 'admin.createSupplierFaq',
  AdminUpdateSupplierFaq = 'admin.updateSupplierFaq',
  AdminDeleteSupplierFaq = 'admin.deleteSupplierFaq',
  SupplierGetSupplierFaqs = 'supplier.getSupplierFaqs',

  // Suppliers.
  AdminCreateSupplier = 'admin.createSupplier',
  AdminGetSupplier = 'admin.getSupplier',
  AdminGetSuppliers = 'admin.getSuppliers',
  AdminUpdateSupplier = 'admin.updateSupplier',
  AdminDeleteSupplier = 'admin.deleteSupplier',

  // Product questions.
  AdminGetProductQuestion = 'admin.getProductQuestion',
  AdminGetProductQuestions = 'admin.getProductQuestions',
  AdminUpdateProductQuestion = 'admin.updateProductQuestion',
  AdminDeleteProductQuestion = 'admin.deleteProductQuestion',
  ClientCreateProductQuestion = 'client.createProductQuestion',
  SupplierAnswerProductQuestion = 'supplier.answerProductQuestion',
  SupplierGetProductQuestion = 'supplier.getProductQuestion',
  SupplierGetProductQuestions = 'supplier.getProductQuestions',

  // Tags.
  CreateTag = 'createTag',
  UpdateTag = 'updateTag',
  DeleteTag = 'deleteTag',

  // Tests.
  CreateTest = 'createTest',
  GetTestAsAdmin = 'getTestAsAdmin',
  GetTestsAsAdmin = 'getTestsAsAdmin',
  UpdateTest = 'updateTest',
  DeleteTest = 'deleteTest',

  // Users.
  GetUsers = 'getUsers',

  // Warehouses.
  CreateWarehouse = 'createWarehouse',
  GetWarehouse = 'getWarehouse',
  GetWarehouses = 'getWarehouses',
  UpdateWarehouse = 'updateWarehouse',
  DeleteWarehouse = 'deleteWarehouse',

  // Withdrawals.
  GetWithdrawal = 'getWithdrawal',
  GetWithdrawals = 'getWithdrawals',
  UpdateWithdrawal = 'updateWithdrawal'
}

export default UserPermission
